/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

:root {
  --primary-color: black;
  --text-primary-color: black;
  --text-secondary-color: #A3A0A0;
  --background-primary-color: #F5F4F4;
  --background-secondary-color: #F5F5F5;
  --stroke: #E1E1E1;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'KoHo', sans-serif;
  font-weight: 500;
}

@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@400;500;600;700;800&display=swap');

input:focus {
  outline: none;
}

.auth-container {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
}

.auth-content {
  margin-top: 5%;
  width: 80%; //800px or less

  display: flex;
  flex-direction: column;
  gap: 40px;


  @media screen and (min-width: 800px) {
    width: 80%;
  }

  @media screen and (min-width: 1200px) {
    width: 70%;
  }

  @media screen and (min-width: 1400px) {
    width: 50%;
    max-width: 60%;
  }
}

.auth-logo-container {
  img {
    width: 100px;
  }
}

.auth-form-container {
  display: flex;

  flex-direction: column;
  gap: 20px;

  h1 {
    font-size: 24px;
    font-weight: 600;
  }
}

.auth-form {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.auth-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  gap: 4px;

  label {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 18px;

    img {
      width: 16px;
    }
  }

  #show-password {
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 10px;
    background-color: var(--background-secondary-color);
    padding-left: 8px;

    transform: translateY(calc(50% + 32px));
  }



  input {
    height: 45px;
    width: calc(100% - 22px); // 20px padding + 4px border
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid var(--stroke);
    background-color: var(--background-secondary-color);
    font-size: 18px;
  }

  button {
    height: 45px;
    width: 100%;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    background-color: var(--primary-color);
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:disabled {
    opacity: 0.3;
  }
}

.auth-submenu {
  display: flex;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: center;
  font-size: 14px;

  margin-top: 4px;
  margin-bottom: 4px;

  a {
    font-weight: 400;
    text-decoration: none;
  }

  p {
    cursor: pointer;
    font-size: 16px;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 12px;
}

.sub-route-title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 12px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.sub-route-container {
  width: 100%;
  height: 100%;
  // background-color: #d69494;
}


//modal
.modal{
  width: 400px;

  input, select{
    width: calc(100% - 22px); 
    height: 45px;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid var(--stroke);
    background-color: var(--background-secondary-color);
  }
}

.modal-header{
  display: flex;
  justify-content: space-between;

  width: calc(100% - 40px);
  padding: 20px;
}

.modal-title{
  font-size: 18px;
  font-weight: 800;
  color: black;
}

.modal-body{
  padding: 20px;
  padding-top: 0 !important;
  width: calc(100% - 40px);
}

.modal-content{
  width: 100%;
  height: 100%;

  .options{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;

      .option{
          font-size: 12px;
          font-weight: 600;
          border: 1px solid var(--stroke);
          border-radius: 8px;
          padding: 5px 10px;
          cursor: pointer;
          background-color: var(--background-secondary-color);
      }
  }


  form{
      display: flex;
      flex-direction: column;
  }

  span {
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 8px;
  }

  

  select {
      width: 100% !important;
      height: 45px;
  }
}

.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  gap: 8px;
  margin-left: 20px;

  width: calc(100% - 40px);

  button {
      height: 45px;
      width: 100%;
      border: 2px solid var(--primary-color);
      border-radius: 8px;
      background-color: var(--primary-color);
      color: white;
      font-weight: 600;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
  }

  button:disabled {
      opacity: 0.3;
  }
}

select:focus{
  outline: none;
}

::-webkit-scrollbar {
  height: 10px;
  
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: rgb(181, 181, 181);
  border-radius: 12px;
}